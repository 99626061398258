<clr-alerts>
  <clr-alert
    *ngIf="update"
    [clrAlertType]="'info'"
    [clrAlertAppLevel]="true"
    [clrAlertClosable]="false"
  >
    <clr-alert-item>
      <span class="alert-text">Aktualisierung erforderlich</span>
      <div class="alert-actions">
        <button class="btn alert-action" (click)="onUpdate()">
          aktualisieren
        </button>
      </div>
    </clr-alert-item>
  </clr-alert>
</clr-alerts>

<div
  class="login-wrapper"
  [style.background-image]="'url(/assets/images/' + background + ')'"
>
  <form class="login" [formGroup]="userEmails" (ngSubmit)="onSubmit()">
    <div class="login-header">
      <!-- <div class="logo"></div> -->

      <!-- <div class="actions">
        <clr-dropdown>
          <button class="btn btn-link btn-sm" clrDropdownTrigger>
            Language
            <cds-icon shape="angle" direction="down"></cds-icon>
          </button>
          <clr-dropdown-menu>
            <div aria-label="Language 1" clrDropdownItem>Language 1</div>
            <div aria-label="Language 2" clrDropdownItem>Language 2</div>
            <div aria-label="Language 3" clrDropdownItem>Language 3</div>
          </clr-dropdown-menu>
        </clr-dropdown>
      </div> -->
    </div>

    <div class="login-body">
      <section class="title">
        <h3 class="welcome">
          <img
            src="assets/logo-c.png"
            alt="Vrielmann Connect"
            width="32"
            height="32"
            [width]="32"
            [height]="32"
          />
          Vrielmann Connect
          <!-- <cds-icon shape="connect"></cds-icon> -->
          <!-- &nbsp;<cds-icon shape="disconnect"></cds-icon> -->
        </h3>
        Herzlich Willkommen
        <h5 class="hint">
          <cds-icon shape="envelope"></cds-icon> Passwortänderung &nbsp;
          <!-- <cds-icon shape="resistor"></cds-icon> &nbsp; -->
          <!-- <cds-icon shape="inductor"></cds-icon> &nbsp; -->
          <!-- <cds-icon shape="capacitor"></cds-icon> &nbsp; -->
        </h5>
      </section>

      <div class="login-group">
        <clr-input-container>
          <label for="username">E-Mail Adresse</label>
          <input
            clrInput
            [class.is-invalid]="username?.invalid && username?.touched"
            type="email"
            id="username"
            name="username"
            autocomplete="username"
            placeholder=""
            formControlName="username"
          />
          <clr-control-error>
            <small *ngIf="username?.errors?.['required']" class="text-danger"
              >E-Mail Adresse ist erforderlich.</small
            >
            <small *ngIf="username?.errors?.['pattern']" class="text-danger"
              >Bitte geben Sie eine gültige E-Mail-Adresse an.</small
            >
          </clr-control-error>
        </clr-input-container>

        <clr-password-container>
          <label for="current-password">Passwort</label>
          <input
            clrPassword
            type="password"
            id="current-password"
            name="current-password"
            autocomplete="new-password"
            formControlName="password"
          />
          <clr-control-helper>
            Verwenden Sie mindestens 10 Zeichen aus 4 verschiedenen
            Zeichenarten, Groß- und Kleinschreibung, Zahlen und Sonderzeichen.
          </clr-control-helper>
          <clr-control-error>
            <small
              *ngIf="userEmails.get('password')?.hasError('required')"
              class="text-danger"
            >
              Passwort ist erforderlich!
            </small>
            <small
              *ngIf="userEmails.get('password')?.hasError('minlength')"
              class="text-danger"
            >
              Mindestlänge sind 8 Zeichen.
            </small>
            <small *ngIf="password?.errors?.['unmatching']" class="text-danger">
              Benutzername und Passwort dürfen nicht übereinstimmen.
            </small>
            <small *ngIf="password?.errors?.['complexity']" class="text-danger">
              Passwort muss aus mindestens vier verschiedenen Zeichenarten
              bestehen.
            </small>
          </clr-control-error>
        </clr-password-container>

        <clr-password-container>
          <label for="new-password">Passwort bestätigen</label>
          <input
            clrPassword
            type="password"
            id="new-password"
            name="new-password"
            autocomplete="new-password"
            formControlName="confirm"
          />
          <clr-control-error>
            <div *ngIf="userEmails.get('confirm')?.hasError('required')">
              Passwortbestätigung ist erforderlich!
            </div>
            <div *ngIf="userEmails.get('confirm')?.hasError('matching')">
              Passwortbestätigung stimmt nicht überein!
            </div>
          </clr-control-error>
        </clr-password-container>

        <clr-alert
          *ngIf="isErrorRenew"
          [clrAlertType]="'danger'"
          [clrAlertClosable]="false"
        >
          <clr-alert-item>
            <span class="alert-text">
              Ungültige Anmeldeinformationen.
              <br />
              <code
                >{{ errorRenew.status }} | {{ errorRenew.statusText }}
                <ng-container *ngIf="errorRenew.error.message">
                  | {{ errorRenew.error.message }}
                </ng-container></code
              >
            </span>
          </clr-alert-item>
        </clr-alert>

        <button
          [clrLoading]="renewBtnState"
          type="submit"
          class="btn btn-primary"
          [disabled]="userEmails.invalid"
        >
          ÄNDERN
        </button>
      </div>
    </div>

    <div class="login-footer">
      <span class="copyright">{{ currentYear }} Vrielmann GmbH</span>
      <!-- <a href="javascript://">Terms</a> -->
    </div>
  </form>
</div>

<clr-modal
  [(clrModalOpen)]="multifactor"
  [clrModalClosable]="false"
  [clrModalStaticBackdrop]="false"
  [clrModalSize]="'md'"
>
  <h3 class="modal-title">
    <section class="title">
      <h3 class="welcome">
        <cds-icon shape="lock" size="lg"></cds-icon>
        Passwort erfolgreich geändert!
      </h3>
    </section>
  </h3>

  <div class="modal-body">
    Ihr Passwort wurde erfolgreich geändert. Sie können sich jetzt mit Ihrem
    neuen Passwort anmelden.
    <p>Bitte speichern Sie Ihr neues Passwort an einem sicheren Ort.</p>
    <!-- <p>
      <a role="button" (click)="signup()">E-Mail erneut senden</a>
    </p> -->
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="onOkay()">
      schließen
    </button>
  </div>
</clr-modal>
