<clr-alerts>
  <clr-alert
    *ngIf="update"
    [clrAlertType]="'info'"
    [clrAlertAppLevel]="true"
    [clrAlertClosable]="false"
  >
    <clr-alert-item>
      <span class="alert-text">Aktualisierung erforderlich</span>
      <div class="alert-actions">
        <button class="btn alert-action" (click)="onUpdate()">
          aktualisieren
        </button>
      </div>
    </clr-alert-item>
  </clr-alert>
</clr-alerts>

<div class="login-wrapper">
  <form class="login">
    <div class="login-header">
      <!-- <div class="logo"></div> -->

      <!-- <div class="actions">
        <clr-dropdown>
          <button class="btn btn-link btn-sm" clrDropdownTrigger>
            Language
            <cds-icon shape="angle" direction="down"></cds-icon>
          </button>
          <clr-dropdown-menu>
            <div aria-label="Language 1" clrDropdownItem>Language 1</div>
            <div aria-label="Language 2" clrDropdownItem>Language 2</div>
            <div aria-label="Language 3" clrDropdownItem>Language 3</div>
          </clr-dropdown-menu>
        </clr-dropdown>
      </div> -->
    </div>

    <div class="login-body">
      <section class="title">
        <h3 class="welcome">
          <img
            src="assets/logo-c.png"
            alt="Vrielmann Connect"
            width="32"
            height="32"
            [width]="32"
            [height]="32"
          />
          Vrielmann Connect
          <!-- &nbsp;<cds-icon shape="disconnect"></cds-icon> -->
        </h3>
        Herzlich Willkommen
        <h5 class="hint">
          <div cds-text="code">
            <cds-icon shape="disconnect" size="md"></cds-icon>
            404 Not Found
          </div>
        </h5>
      </section>

      <div class="login-group">
        <button type="button" class="btn btn-primary" (click)="onSubmit()">
          <cds-icon shape="home"></cds-icon> Startseite
        </button>
      </div>
    </div>

    <div class="login-footer">
      <span class="copyright">{{ currentYear }} Vrielmann GmbH</span>
      <!-- <a href="javascript://">Terms</a> -->
    </div>
  </form>
</div>
