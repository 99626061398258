import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { selectProfil } from '../views/users/+profil/profil.selectors';

@Injectable()
export class RouteManagerInterceptor implements HttpInterceptor {
  constructor(private store: Store) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    if (!request.headers.has('X-Route-Interceptor')) {
      return next.handle(request);
    }

    return this.store.select(selectProfil).pipe(
      // take(1),
      switchMap((profil) => {
        const x = request.headers.get('X-Route') || '';
        let route = x;

        const isManager =
          profil?.Roles?.find((r) => r.Symbol === 'manager') !== undefined;

        if (isManager) {
          route = `manager/${x}`;
        }

        const isService =
          profil?.Roles?.find((r) => r.Symbol === 'service') !== undefined;

        if (isService) {
          if (profil.Uid === '000000000') {
            route = `service/${x}`;
          }
          // route = `service/${x}`;
        }

        if (!isManager && !isService) {
          route = x;
        }

        if (request.headers.has('X-Param')) {
          route += request.headers.get('X-Param');
        }

        if (request.headers.has('X-Query')) {
          route += request.headers.get('X-Query');
        }
        const modifiedUrl = `${request.url}/${route}`;

        const modifiedRequest = request.clone({
          url: modifiedUrl,
          headers: request.headers
            .delete('X-Route-Interceptor')
            .delete('X-Route')
            .delete('X-Param'),
        });

        return next.handle(modifiedRequest);
      }),
    );
  }
}
