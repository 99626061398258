<clr-alerts>
  <clr-alert
    *ngIf="update"
    [clrAlertType]="'info'"
    [clrAlertAppLevel]="true"
    [clrAlertClosable]="false"
  >
    <clr-alert-item>
      <span class="alert-text">Aktualisierung erforderlich</span>
      <div class="alert-actions">
        <button class="btn alert-action" (click)="onUpdate()">
          aktualisieren
        </button>
      </div>
    </clr-alert-item>
  </clr-alert>
</clr-alerts>

<div
  class="login-wrapper"
  [style.background-image]="'url(/assets/images/' + background + ')'"
>
  <form class="login">
    <div class="login-header">
      <!-- <div class="logo"></div> -->

      <!-- <div class="actions">
        <clr-dropdown>
          <button class="btn btn-link btn-sm" clrDropdownTrigger>
            Language
            <cds-icon shape="angle" direction="down"></cds-icon>
          </button>
          <clr-dropdown-menu>
            <div aria-label="Language 1" clrDropdownItem>Language 1</div>
            <div aria-label="Language 2" clrDropdownItem>Language 2</div>
            <div aria-label="Language 3" clrDropdownItem>Language 3</div>
          </clr-dropdown-menu>
        </clr-dropdown>
      </div> -->
    </div>

    <div class="login-body">
      <section class="title">
        <h3 class="welcome">
          <img
            src="assets/logo-c.png"
            alt="Vrielmann Connect"
            width="32"
            height="32"
            [width]="32"
            [height]="32"
          />
          Vrielmann Connect
          <!-- <cds-icon shape="connect"></cds-icon> -->
          <!-- &nbsp;<cds-icon shape="disconnect"></cds-icon> -->
        </h3>
        Herzlich Willkommen
        <h5 class="hint">
          <ng-container *ngIf="!succes && !error">
            <cds-icon shape="info-standard"></cds-icon>
            Verifizierung
          </ng-container>
          <ng-container *ngIf="succes">
            <cds-icon shape="success-standard" status="success"></cds-icon>
            Verifizierung
            <p>
              <cds-icon shape="calendar"></cds-icon>
              {{ model.SignUp.Verified | date: "fullDate" }} |
              {{ model.SignUp.Verified | date: "HH:mm" }} Uhr
            </p>
          </ng-container>
          <ng-container *ngIf="error">
            <cds-icon shape="error-standard" status="danger"></cds-icon>
            Verifizierung
          </ng-container>
        </h5>
      </section>

      <div class="login-group">
        <!-- <clr-input-container>
        <label>E-Mail Adresse</label>
        <input
          clrInput
          type="text"
          [(ngModel)]="model.Username"
          name="Username"
          disabled
        />
      </clr-input-container> -->

        <ng-container *ngIf="!succes && !error">
          <span class="spinner">Loading...</span>
        </ng-container>

        <ng-container *ngIf="succes">
          Ihre E-Mail-Adresse {{ model.Username }} wurde erfolgreich
          verifiziert. Sie können sich jetzt anmelden.
        </ng-container>

        <ng-container *ngIf="error">
          Die Verifizierung war nicht erfolgreich. Bitte überprüfen Sie den Link
          oder kontaktieren Sie den Support.
        </ng-container>

        <!-- <p>
        <a
          [routerLink]="['/signin']"
          [queryParams]="{ username: model.Username }"
          >Hier anmelden</a
        >
      </p> -->

        <p></p>

        <a routerLink="/signin" class="btn btn-primary">LOGIN</a>
      </div>
    </div>

    <div class="login-footer">
      <span class="copyright">{{ currentYear }} Vrielmann GmbH</span>
      <!-- <a href="javascript://">Terms</a> -->
    </div>
  </form>
</div>
