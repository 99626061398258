import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { User } from 'src/app/models/User';
import { AuthService } from 'src/app/services/auth.service';
import { StatusService } from 'src/app/services/status.service';

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.css'],
})
export class VerifyComponent implements OnInit {
  currentYear = new Date().getFullYear();

  private _background = [
    'Hintergrund_Microsoft_Teams_v1.jpg',
    'Hintergrund_Microsoft_Teams_v2.jpg',
    'Hintergrund_Microsoft_Teams_v3.jpg',
    'Hintergrund_Microsoft_Teams_v4.jpg',
    'Hintergrund_Microsoft_Teams_v5.jpg',
  ];
  background = this._background[0];

  model = {} as User;
  succes = false;
  error = false;

  update = false;

  verifyOberserver = {
    next: (user: any) => this.onVerifyNext(user),
    error: (error: any) => this.onVerifyError(error),
    complete: () => this.onVerifyComplete(),
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private statusService: StatusService,
  ) {
    const min = 0;
    const max = this._background.length - 1;

    this.background =
      this._background[Math.floor(Math.random() * (max - min + 1)) + min];
  }

  ngOnInit() {
    this.activatedRoute.queryParamMap.subscribe((params: ParamMap) => {
      this.succes = false;
      this.error = false;
      const token = params.get('token') || '';
      this.authService.verify(token).subscribe(this.verifyOberserver);
    });

    this.statusService.currentStatus.subscribe((status: boolean) => {
      this.update = status;
    });
  }

  onVerifyNext(user: User) {
    this.succes = true;
    this.model = user;
  }

  onVerifyError(error: any) {
    this.error = true;
  }

  onVerifyComplete() {
    //
  }

  onUpdate() {
    document.location.reload();
  }
}
