import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ClrLoadingState } from '@clr/angular';
import { User } from 'src/app/models/User';
import { AuthService } from 'src/app/services/auth.service';
import { StatusService } from 'src/app/services/status.service';

@Component({
  selector: 'app-request',
  templateUrl: './request-pwd.component.html',
  styleUrls: ['./request-pwd.component.css'],
})
export class RequestPwdComponent implements OnInit {
  currentYear = new Date().getFullYear();

  private _background = [
    'Hintergrund_Microsoft_Teams_v1.jpg',
    'Hintergrund_Microsoft_Teams_v2.jpg',
    'Hintergrund_Microsoft_Teams_v3.jpg',
    'Hintergrund_Microsoft_Teams_v4.jpg',
    'Hintergrund_Microsoft_Teams_v5.jpg',
  ];
  background = this._background[0];

  multifactor = false;
  errorRequest = false;
  requestErrorResponse = new HttpErrorResponse({});

  submitBtnState: ClrLoadingState = ClrLoadingState.DEFAULT;

  userEmails = this.formBuilder.group({
    username: [
      '',
      [
        Validators.required,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,5}$'),
      ],
    ],
  });

  update = false;

  get username() {
    return this.userEmails.get('username');
  }

  requestOberserver = {
    next: (user: any) => this.onRequestNext(user),
    error: (error: any) => this.onRequestError(error),
    complete: () => this.onRequestComplete(),
  };

  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private statusService: StatusService,
  ) {
    const min = 0;
    const max = this._background.length - 1;

    this.background =
      this._background[Math.floor(Math.random() * (max - min + 1)) + min];
  }

  ngOnInit(): void {
    this.statusService.currentStatus.subscribe((status: boolean) => {
      this.update = status;
    });
  }

  onSubmit() {
    this.submitBtnState = ClrLoadingState.LOADING;
    this.errorRequest = false;
    this.requestErrorResponse = new HttpErrorResponse({});

    const user = {
      Username: this.username?.value,
    };

    this.authService.requestPassword(user).subscribe(this.requestOberserver);
  }

  onRequestNext(user: User) {
    this.submitBtnState = ClrLoadingState.SUCCESS;
    this.multifactor = true;
  }

  onRequestError(error: HttpErrorResponse) {
    this.submitBtnState = ClrLoadingState.SUCCESS;

    this.errorRequest = true;
    this.requestErrorResponse = error;
  }

  onRequestComplete() {
    //
  }

  onUpdate() {
    document.location.reload();
  }
}
