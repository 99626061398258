import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ClrLoadingState } from '@clr/angular';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css'],
})
export class SignInComponent implements OnInit {
  public returnUrl = '';

  currentYear = new Date().getFullYear();

  private _background = [
    'Hintergrund_Microsoft_Teams_v1.jpg',
    'Hintergrund_Microsoft_Teams_v2.jpg',
    'Hintergrund_Microsoft_Teams_v3.jpg',
    'Hintergrund_Microsoft_Teams_v4.jpg',
    'Hintergrund_Microsoft_Teams_v5.jpg',
  ];
  background = this._background[0];

  multifactor = false;

  public isErrorSignin = false;
  public errorSignin = new HttpErrorResponse({});
  public isErrorConfirm = false;
  public errorConfirm = new HttpErrorResponse({});

  userEmails = this.formBuilder.group({
    Token: new FormControl('', {
      nonNullable: true,
    }),
    Username: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    Password: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
  });

  get Username() {
    return this.userEmails.get('Username');
  }

  get Password() {
    return this.userEmails.get('Password');
  }

  get Token() {
    return this.userEmails.get('Token');
  }

  signInOberserver = {
    next: (user: any) => this.onSignInNext(user),
    error: (error: HttpErrorResponse) => this.onSignInError(error),
    complete: () => this.onSignInComplete(),
  };

  confirmOberserver = {
    next: (user: any) => this.onConfirmNext(user),
    error: (error: HttpErrorResponse) => this.onConfirmError(error),
    complete: () => this.onConfirmComplete(),
  };

  submitBtnState: ClrLoadingState = ClrLoadingState.DEFAULT;

  update = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private formBuilder: FormBuilder,
  ) {
    const min = 0;
    const max = this._background.length - 1;

    this.background =
      this._background[Math.floor(Math.random() * (max - min + 1)) + min];
  }

  ngOnInit(): void {
    this.returnUrl =
      this.activatedRoute.snapshot.queryParams['returnUrl'] || '/';
  }

  onCodeChanged(code: string) {
    this.Token?.setValue(code);
  }

  onCodeCompleted(code: string) {
    this.Token?.setValue(code);
  }

  signin() {
    this.submitBtnState = ClrLoadingState.LOADING;

    this.isErrorSignin = false;
    this.isErrorConfirm = false;

    const userEmails = this.userEmails.getRawValue();

    this.authService
      .signin(userEmails.Username, userEmails.Password, userEmails.Token)
      .subscribe(this.signInOberserver);

    // this.store.dispatch(
    //   UserActions.signinUser({
    //     username: data.Username,
    //     password: data.Password,
    //     token: data.Token,
    //   }),
    // );
  }

  onSignInNext(result: any) {
    this.isErrorSignin = false;

    if (result.User.MAIL.IsEnabled) {
      this.multifactor = true;
    } else {
      this.authService.writeToken(result.Token);
      this.router.navigateByUrl(this.returnUrl);
    }

    this.submitBtnState = ClrLoadingState.SUCCESS;
  }

  onSignInError(error: HttpErrorResponse) {
    this.isErrorSignin = true;
    this.errorSignin = error;

    this.submitBtnState = ClrLoadingState.ERROR;
  }

  onSignInComplete() {
    //
  }

  confirm() {
    this.isErrorSignin = false;
    this.isErrorConfirm = false;

    const userEmails = this.userEmails.getRawValue();

    this.authService
      .confirm(userEmails.Username, userEmails.Password, userEmails.Token)
      .subscribe(this.confirmOberserver);

    // this.store.dispatch(
    //   UserActions.confirmnUser({
    //     username: data.Username,
    //     password: data.Password,
    //     token: data.Token,
    //   }),
    // );
  }

  onConfirmNext(result: any) {
    this.authService.writeToken(result.Token);
    this.router.navigateByUrl(this.returnUrl);
  }

  onConfirmError(error: HttpErrorResponse) {
    this.isErrorConfirm = true;
    this.errorConfirm = error;
  }

  onConfirmComplete() {
    //
  }

  onUpdate() {
    document.location.reload();
  }
}
