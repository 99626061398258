<clr-main-container>
  <!--
    .alert-danger   -> fwt, alarm
    .alert-warning  -> fwt, warnung
    .alert-info     -> fwt, info      | update
    .alert-success
  -->

  <!-- Update -->
  <clr-alerts>
    <clr-alert
      *ngIf="update"
      [clrAlertType]="'info'"
      [clrAlertAppLevel]="true"
      [clrAlertClosable]="false"
    >
      <clr-alert-item>
        <span class="alert-text">Aktualisierung erforderlich</span>
        <div class="alert-actions">
          <button class="btn alert-action" (click)="onUpdate()">
            aktualisieren
          </button>
        </div>
      </clr-alert-item>
    </clr-alert>
  </clr-alerts>

  <!-- Telecontrol -->
  <clr-alerts>
    <clr-alert
      *ngIf="alarm > 0"
      [clrAlertType]="'danger'"
      [clrAlertAppLevel]="true"
      [clrAlertClosable]="false"
    >
      <clr-alert-item>
        <span class="alert-text"
          ><a routerLink="/telecontrols/messages"
            >Es stehen <strong>{{ alarm }}</strong> Alarme an.</a
          ></span
        >
      </clr-alert-item>
    </clr-alert>

    <clr-alert
      *ngIf="warn > 0"
      [clrAlertType]="'warning'"
      [clrAlertAppLevel]="true"
      [clrAlertClosable]="false"
    >
      <clr-alert-item>
        <span class="alert-text"
          ><a routerLink="/telecontrols/messages"
            >Es stehen <strong>{{ warn }}</strong> Warnungen an.</a
          ></span
        >
      </clr-alert-item>
    </clr-alert>

    <clr-alert
      *ngIf="info > 0"
      [clrAlertType]="'info'"
      [clrAlertAppLevel]="true"
      [clrAlertClosable]="false"
    >
      <clr-alert-item>
        <span class="alert-text"
          ><a routerLink="/telecontrols/messages"
            >Es stehen <strong>{{ info }}</strong> Meldungen an.</a
          ></span
        >
      </clr-alert-item>
    </clr-alert>
  </clr-alerts>

  <clr-header class="header-2">
    <div class="branding">
      <a routerLink="/" class="nav-link">
        <cds-icon shape="vrielmann-bw" class="test"></cds-icon>
        <span class="title">&nbsp;Vrielmann</span>
      </a>
    </div>

    <div class="header-nav">
      <!-- <div class="header-nav" [clr-nav-level]="1"> -->
      <!-- <a
        routerLinkActive="active"
        routerLink="/factories"
        class="nav-link nav-text"
        >Firmen</a
      > -->
    </div>

    <div class="header-actions">
      <!-- <clr-dropdown>
        <button class="nav-text" clrDropdownTrigger (click)="onToggleTheme()">
          <div
            *ngIf="readThemeMode() === 'light'; then lightMode; else darkMode"
          ></div>
          <ng-template #lightMode>
            <cds-icon shape="moon"></cds-icon>
          </ng-template>
          <ng-template #darkMode>
            <cds-icon shape="sun"></cds-icon>
          </ng-template>
        </button>
      </clr-dropdown> -->

      <ng-container *ngIf="!risksOnline$.value">
        <div class="nav-link nav-icon-text">
          <cds-icon shape="no-wifi"></cds-icon>
          <span class="nav-text">Offline</span>
        </div>
      </ng-container>

      <ng-container *ngIf="risksInSync$.value">
        <div class="nav-link nav-icon-text">
          <cds-icon shape="sync"></cds-icon>
          <span class="nav-text">InSync</span>
        </div>
      </ng-container>

      <clr-dropdown>
        <button class="nav-text" clrDropdownTrigger>
          <cds-icon shape="user"></cds-icon>
          <div *ngIf="profil$ | async as profil">
            &nbsp;{{ profil.Firstname | uppercase }}&nbsp;
          </div>
          <cds-icon shape="angle" direction="down"></cds-icon>
        </button>
        <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
          <h4 class="dropdown-header">Version V1.0.3</h4>

          <div class="dropdown-divider" role="separator"></div>

          <a routerLink="/users/profil" clrDropdownItem>
            <cds-icon shape="cog"></cds-icon>
            Einstellungen
          </a>

          <div class="dropdown-divider" role="separator"></div>
          <a
            clrDropdownItem
            (click)="onLogout()"
            (keydown)="onLogout()"
            tabindex="0"
          >
            <cds-icon shape="logout"></cds-icon>
            Abmelden
          </a>
        </clr-dropdown-menu>
      </clr-dropdown>

      <a
        class="nav-link tooltip tooltip-sm tooltip-bottom-left"
        role="tooltip"
        [routerLink]="['/info/feedback']"
      >
        <cds-icon shape="chat-bubble"></cds-icon>
        <span class="tooltip-content">Feedback</span>
      </a>
    </div>
  </clr-header>

  <div class="content-container">
    <clr-vertical-nav
      class="nav-trigger--bottom"
      [clrVerticalNavCollapsible]="true"
      [(clrVerticalNavCollapsed)]="clrVerticalNavCollapsed"
      [clr-nav-level]="1"
    >
      <!-- <ng-scrollbar></ng-scrollbar> -->
      <!-- <div class="nav-header">Allgemein</div> -->
      <a clrVerticalNavLink routerLinkActive="active" routerLink="/home"
        ><cds-icon clrVerticalNavIcon shape="home"></cds-icon>Startseite</a
      >

      <div class="nav-divider"></div>

      <div class="nav-header">Stammdaten</div>

      <a clrVerticalNavLink routerLinkActive="active" routerLink="/factories"
        ><cds-icon clrVerticalNavIcon shape="factory"></cds-icon>Firmen</a
      >

      <a clrVerticalNavLink routerLinkActive="active" routerLink="/contracts"
        ><cds-icon clrVerticalNavIcon shape="contract"></cds-icon>Verträge</a
      >

      <a clrVerticalNavLink routerLinkActive="active" routerLink="/stations"
        ><cds-icon clrVerticalNavIcon shape="host-group"></cds-icon>Stationen</a
      >

      <div class="nav-divider"></div>

      <div class="nav-header">Fernwirktechnik</div>

      <a
        clrVerticalNavLink
        routerLinkActive="active"
        routerLink="/telecontrols/messages"
      >
        <cds-icon clrVerticalNavIcon shape="cpu"></cds-icon>Meldungen</a
      >

      <!-- <a
        clrVerticalNavLink
        routerLink="/telecontrols/presets"
        routerLinkActive="active"
      >
        <cds-icon clrVerticalNavIcon shape="cpu"></cds-icon>Vorgaben</a
      > -->

      <!-- <a
        clrVerticalNavLink
        routerLink="/telecontrols/measures"
        routerLinkActive="active"
      >
        <cds-icon clrVerticalNavIcon shape="cpu"></cds-icon>Messwerte</a
      > -->

      <div *ngIf="(profil$ | async)?.Features?.Risks">
        <div class="nav-divider"></div>
        <div class="nav-header">Gefährdungsbeurteilung</div>
        <a
          clrVerticalNavLink
          routerLink="/risks"
          routerLinkActive="active"
          (click)="checkRiskSync()"
        >
          <cds-icon shape="form"></cds-icon>Schaltanlagen
        </a>
      </div>

      <!--  -->
      <!-- <div class="nav-divider"></div> -->
      <!-- <a
        clrVerticalNavLink
        routerLink="/assessment/review"
        routerLinkActive="active"
      >
        Bewertung
      </a>
      <a
        clrVerticalNavLink
        routerLink="/assessment/evaluation"
        routerLinkActive="active"
      >
        Auswertung
      </a> -->

      <!-- Benutzerverwaltung -->
      <ng-container *ngIf="isManager$ | async as isManager">
        <div class="nav-divider"></div>

        <div class="nav-header">Benutzerverwaltung</div>

        <ng-container *ngIf="isService$ | async as isService">
          <a
            clrVerticalNavLink
            routerLink="/users/all"
            routerLinkActive="active"
          >
            <cds-icon clrVerticalNavIcon shape="users"></cds-icon>Alle
          </a>
        </ng-container>
        <a
          clrVerticalNavLink
          routerLink="/users/group"
          routerLinkActive="active"
        >
          <cds-icon clrVerticalNavIcon shape="user"></cds-icon>Benutzer
        </a>
        <a
          clrVerticalNavLink
          routerLink="/users/factories"
          routerLinkActive="active"
        >
          <cds-icon clrVerticalNavIcon shape="assign-user"></cds-icon>Firmen
        </a>
      </ng-container>

      <div class="nav-divider"></div>

      <div class="nav-header">Einstellungen</div>

      <a clrVerticalNavLink routerLinkActive="active" routerLink="/users/profil"
        ><cds-icon clrVerticalNavIcon shape="cog"></cds-icon>Einstellungen</a
      >

      <div class="nav-divider"></div>

      <div class="nav-header">Datenschutz</div>

      <a
        clrVerticalNavLink
        routerLinkActive="active"
        routerLink="/info/impressum"
        ><cds-icon clrVerticalNavIcon shape="gavel"></cds-icon>Impressum</a
      >

      <a
        clrVerticalNavLink
        routerLinkActive="active"
        routerLink="/info/datenschutz"
        ><cds-icon clrVerticalNavIcon shape="gavel"></cds-icon>Datenschutz</a
      >
    </clr-vertical-nav>

    <main class="content-area">
      <div class="clr-row">
        <div class="clr-col-12">
          <router-outlet></router-outlet>
        </div>
      </div>
      <p></p>
    </main>
  </div>
</clr-main-container>
