<clr-alerts>
  <clr-alert
    *ngIf="update"
    [clrAlertType]="'info'"
    [clrAlertAppLevel]="true"
    [clrAlertClosable]="false"
  >
    <clr-alert-item>
      <span class="alert-text">Aktualisierung erforderlich</span>
      <div class="alert-actions">
        <button class="btn alert-action" (click)="onUpdate()">
          aktualisieren
        </button>
      </div>
    </clr-alert-item>
  </clr-alert>
</clr-alerts>

<div
  class="login-wrapper"
  [style.background-image]="'url(/assets/images/' + background + ')'"
>
  <form class="login" [formGroup]="userEmails" (ngSubmit)="onSubmit()">
    <div class="login-header">
      <!-- <div class="logo"></div> -->

      <!-- <div class="actions">
        <clr-dropdown>
          <button class="btn btn-link btn-sm" clrDropdownTrigger>
            Language
            <cds-icon shape="angle" direction="down"></cds-icon>
          </button>
          <clr-dropdown-menu>
            <div aria-label="Language 1" clrDropdownItem>Language 1</div>
            <div aria-label="Language 2" clrDropdownItem>Language 2</div>
            <div aria-label="Language 3" clrDropdownItem>Language 3</div>
          </clr-dropdown-menu>
        </clr-dropdown>
      </div> -->
    </div>

    <div class="login-body">
      <section class="title">
        <h3 class="welcome">
          <img
            src="assets/logo-c.png"
            alt="Vrielmann Connect"
            width="32"
            height="32"
            [width]="32"
            [height]="32"
          />
          Vrielmann Connect
          <!-- <cds-icon shape="connect"></cds-icon> -->
          <!-- &nbsp;<cds-icon shape="disconnect"></cds-icon> -->
        </h3>
        Herzlich Willkommen
        <h5 class="hint">
          <cds-icon shape="envelope"></cds-icon> Passwortanforderung&nbsp;
          <!-- <cds-icon shape="resistor"></cds-icon>&nbsp; -->
          <!-- <cds-icon shape="inductor"></cds-icon>&nbsp; -->
          <!-- <cds-icon shape="capacitor"></cds-icon> -->
        </h5>
      </section>
      <div class="login-group">
        <clr-input-container>
          <label for="username" class="clr-sr-only">Username</label>
          <!-- <input
          clrInput
          [(ngModel)]="requestUserData.Username"
          type="text"
          id="username"
          name="username"
          autocomplete="username"
          placeholder="Username"
        /> -->
          <input
            clrInput
            [class.is-invalid]="username?.invalid && username?.touched"
            type="email"
            id="username"
            name="username"
            autocomplete="username"
            placeholder=""
            formControlName="username"
          />
          <clr-control-error>
            <!-- <div
          *ngIf="(username?.invalid && username?.touched) || username?.dirty"
        > -->
            <small *ngIf="username?.errors?.['required']" class="text-danger"
              >E-Mail Adresse ist erforderlich.</small
            >
            <small *ngIf="username?.errors?.['pattern']" class="text-danger"
              >Bitte geben Sie eine gültige E-Mail-Adresse an.</small
            >
            <!-- </div> -->
          </clr-control-error>
        </clr-input-container>

        <div *ngIf="errorRequest" class="alert alert-danger" role="alert">
          <div class="alert-items">
            <div class="alert-item static">
              <div class="alert-icon-wrapper">
                <cds-icon
                  class="alert-icon"
                  shape="exclamation-circle"
                ></cds-icon>
              </div>
              <span class="alert-text">Ungültige Anmeldeinformationen</span>
              <!-- <br />
              <code
                >{{ requestErrorResponse.status }} |
                {{ requestErrorResponse.statusText }}
                <ng-container *ngIf="requestErrorResponse?.error.name">
                  | {{ requestErrorResponse.error.name }}
                </ng-container>
              </code> -->
            </div>
          </div>
        </div>

        <button
          type="submit"
          class="btn btn-primary"
          [disabled]="userEmails.invalid"
          [clrLoading]="submitBtnState"
        >
          ANFORDERN
        </button>
        <!-- <div *ngIf="errorLogin" class="error active">
        <br />name: {{ loginErrorResponse.name }} <br /><br />url:
        {{ loginErrorResponse.url }} <br /><br />status:
        {{ loginErrorResponse.status }} <br /><br />statusText:
        {{ loginErrorResponse.statusText }}
      </div> -->
      </div>
    </div>

    <div class="login-footer">
      <span class="copyright">{{ currentYear }} Vrielmann GmbH</span>
      <!-- <a href="javascript://">Terms</a> -->
    </div>
  </form>
</div>

<clr-modal
  [(clrModalOpen)]="multifactor"
  [clrModalClosable]="false"
  [clrModalStaticBackdrop]="false"
  [clrModalSize]="'md'"
>
  <h3 class="modal-title">
    <section class="title">
      <h3 class="welcome">
        <cds-icon shape="lock" size="lg"></cds-icon>
        Vielen Dank!
      </h3>
    </section>
  </h3>

  <div class="modal-body">
    Wir haben eine E-Mail zur Erstellung eines neuen Passworts an die von Ihnen
    angegebene Adresse gesendet:
    <strong>{{ username?.value }}</strong
    >.
    <p>
      Bitte überprüfen Sie Ihr E-Mail-Postfach und klicken Sie auf den Link zum
      rurücksetzen, um Ihr Password zu ändern. Falls Sie die E-Mail nicht finden
      können, überprüfen Sie Ihren Spam-Ordner.
    </p>
    <!-- <p>
      <a role="button" (click)="signup()">E-Mail erneut senden</a>
    </p> -->
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="multifactor = false">
      schließen
    </button>
  </div>
</clr-modal>
